import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TransitionLink from "gatsby-plugin-transition-link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="main-container py-72">
      <h1 className="display-1">Erreur 404</h1>
      <p className="lead">La page demandée n'existe pas</p>
      <TransitionLink
        className="link"
        to="/"
        exit={{ length: 1 }}
        entry={{ delay: 1 }}
      >
        Revenir à l'accueil
      </TransitionLink>
    </div>

  </Layout>
)

export default NotFoundPage
